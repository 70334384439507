import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../query-client.mjs';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { styled } from 'styled-components';

type Birkman = {
  _id: string;
  application: string;
  clientIds: string[];
  email: string;
  familyName: string;
  givenName: string;
  scoreDate: string;
  subjectId: string;
};

type Response = {
  birkmans: Birkman[];
  startDate: string;
  endDate: string;
};

const Wrapper = styled.div`
  padding: 16px;
`;

const MonthSelectorWrapper = styled.div`
  padding: 16px 0;
`;

export const MonthScreen = (): React.JSX.Element => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const [year, setYear] = useState(currentYear.toString());
  const [month, setMonth] = useState(
    (now.getMonth() + 1).toString().padStart(2, '0'),
  );

  const { data, isLoading } = useQuery<Response>(
    {
      queryKey: [`/api/v1/listings/months`, { queryParams: { year, month } }],
    },
    queryClient,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Error loading data</div>;
  }

  return (
    <Wrapper>
      <h2>
        {format(data.startDate, 'yyyy-MM-dd HH:mm')} &mdash;{' '}
        {format(data.endDate, 'yyyy-MM-dd HH:mm')} ({data?.birkmans.length})
      </h2>
      <MonthSelectorWrapper>
        Year:{' '}
        <select onChange={(e) => setYear(e.target.value)} value={year}>
          {Array.from({ length: currentYear - 1990 + 1 }).map((_, i) => (
            <option
              key={i + 1990}
              value={`${i + 1990}`}
            >{`${i + 1990}`}</option>
          ))}
        </select>{' '}
        Month:{' '}
        <select onChange={(e) => setMonth(e.target.value)} value={month}>
          {Array.from({ length: 12 }).map((_, i) => (
            <option
              key={`${i + 1}`.padStart(2, '0')}
              value={`${i + 1}`.padStart(2, '0')}
            >
              {`${i + 1}`.padStart(2, '0')}
            </option>
          ))}
        </select>
      </MonthSelectorWrapper>
      {data.birkmans.length > 0 ?
        <div className="grid">
          {data.birkmans.map((birkman) => {
            return (
              <Fragment key={birkman._id}>
                <div>
                  {birkman.scoreDate ?
                    format(birkman.scoreDate, 'yyyy-MM-dd')
                  : '-'}
                </div>
                <div>{birkman.subjectId ?? '-'}</div>
                <div>
                  {[birkman.familyName, birkman.givenName].join(', ') ?? '-'}
                </div>
                <div>{birkman.email.toLowerCase() ?? '-'}</div>
                <div>{birkman.application ?? '-'}</div>
              </Fragment>
            );
          })}
        </div>
      : <div>No data for month</div>}
    </Wrapper>
  );
};
