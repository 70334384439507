import { useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { queryClient } from '../../query-client.mjs';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { styled } from 'styled-components';

const route = getRouteApi('/clients/$clientId');

type Birkman = {
  _id: string;
  application: string;
  clientIds: string[];
  email: string;
  familyName: string;
  givenName: string;
  scoreDate: string;
  subjectId: string;
};

type Response = {
  birkmans: Birkman[];
  clientId: string;
  clientName: string;
  startDate: string | null;
  endDate: string | null;
};

const Wrapper = styled.div`
  padding: 16px;
`;

const DateRangeSelectorWrapper = styled.div`
  padding: 16px 0;
`;

export const ClientScreen = (): React.JSX.Element => {
  const { clientId } = route.useParams();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const queryParams: {
    startDate?: string;
    endDate?: string;
  } = {};

  if (startDate !== null) {
    queryParams.startDate = startDate;
  }

  if (endDate !== null) {
    queryParams.endDate = endDate;
  }

  const { data, isLoading } = useQuery<Response>(
    {
      queryKey: [`/api/v1/listings/clients/${clientId}`, { queryParams }],
    },
    queryClient,
  );

  return (
    <Wrapper>
      <h2>
        {isLoading ?
          <>Loading...</>
        : <>
            {data.clientName} ({data.clientId})<br />
            {data?.startDate ? format(data.startDate, 'yyyy-MM-dd HH:mm') : ''}
            {data?.startDate && data?.endDate ?
              <> &mdash; </>
            : null}
            {data?.endDate ? format(data.endDate, 'yyyy-MM-dd HH:mm') : ''}
            {data?.startDate || data?.endDate ? ' ' : ''}(
            {data?.birkmans.length})
          </>
        }
      </h2>
      <DateRangeSelectorWrapper>
        From:{' '}
        <input
          type="datetime-local"
          onChange={(e) => setStartDate(e.target.value)}
          value={startDate ?? ''}
        />{' '}
        To:{' '}
        <input
          type="datetime-local"
          onChange={(e) => setEndDate(e.target.value)}
          value={endDate ?? ''}
        />{' '}
      </DateRangeSelectorWrapper>
      {isLoading ?
        <div>Loading...</div>
      : null}
      {!isLoading && !data ?
        <div>Error loading data</div>
      : null}
      {!isLoading && data.birkmans.length > 0 ?
        <div className="grid">
          {data.birkmans.map((birkman) => {
            return (
              <Fragment key={birkman._id}>
                <div>
                  {birkman.scoreDate ?
                    format(birkman.scoreDate, 'yyyy-MM-dd')
                  : '-'}
                </div>
                <div>{birkman.subjectId ?? '-'}</div>
                <div>
                  {[birkman.familyName, birkman.givenName].join(', ') ?? '-'}
                </div>
                <div>{birkman.email.toLowerCase() ?? '-'}</div>
                <div>{birkman.application ?? '-'}</div>
              </Fragment>
            );
          })}
        </div>
      : <div>No data for client</div>}
    </Wrapper>
  );
};
