import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../query-client.mjs';
import { styled } from 'styled-components';
import { Client } from '../../types/client.mjs';
import { Link } from '@tanstack/react-router';

const Wrapper = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Clients = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 16px;
`;

const ClientLink = styled(Link)`
  color: #333333;
  text-decoration: none;
  display: block;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 16px;
`;

export const ClientsScreen = (): React.JSX.Element => {
  const { data, isLoading } = useQuery<{ clients: Client[] }>(
    {
      queryKey: [`/api/v1/clients`],
    },
    queryClient,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Error loading data</div>;
  }

  if (!data.clients.length) {
    return <div>No clients found</div>;
  }

  return (
    <Wrapper>
      <Title>All Clients</Title>
      <Clients>
        {data.clients.map((client) => {
          return (
            <ClientLink
              to="/clients/$clientId"
              params={{ clientId: client._id }}
              key={client._id}
            >
              {client.name ?? '-'}
            </ClientLink>
          );
        })}
      </Clients>
    </Wrapper>
  );
};
