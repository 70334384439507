import axios from 'axios';

export const apiEndpoint =
  __DEV__ ? 'http://localhost:50015' : 'https://dashboard-api.elaura.com';

export const axiosInstance = axios.create({
  baseURL: apiEndpoint,
  headers: {
    'Content-Type': 'application/json',
    'X-Application': 'dashboard-client',
    'X-Client-Type': 'web',
  },
  timeout: 3000,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers['X-Request-Id'] = globalThis.crypto.randomUUID();

  return config;
});
