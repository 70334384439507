import { createRoot } from 'react-dom/client';

if (__DEV__) {
  new EventSource('/esbuild').addEventListener('change', () => {
    location.reload();
  });
}

import { App } from './App.js';
import { StrictMode } from 'react';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
