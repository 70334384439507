import { MouseEventHandler } from 'react';
import { Client } from '../../types/client.mjs';

type Props = {
  client: Client;
};

export const ClientListItem = (props: Props) => {
  const { client } = props;
  const { _id, name } = client;

  const onClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    alert(`${name} (${_id})`);
  };

  return (
    <tr>
      <td>
        <a onClick={onClick}>{name}</a>
      </td>
    </tr>
  );
};
