import { useQuery } from '@tanstack/react-query';

import { queryClient } from './query-client.mjs';
import { Client } from './types/client.mjs';
import { ClientList } from './components/clients/ClientList.js';
import { Router } from './Router.js';
import { GlobalStyles } from './GlobalStyles.js';

export const App = () => {
  return (
    <>
      <GlobalStyles />
      <Router />
    </>
  );

  const { data, isLoading } = useQuery<{ clients: Client[] }>(
    {
      queryKey: ['/api/v1/clients'],
    },
    queryClient,
  );

  return (
    <>
      <title>Elaura Dashboard</title>
      <div>
        <ClientList />
        <h1>Elaura Dashboard</h1>
        <div>
          {isLoading ? null : (
            data?.clients.map((client) => {
              return (
                <div key={client._id} onClick={() => alert(client.name)}>
                  {client.name}
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
