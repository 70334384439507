import { Client } from '../../types/client.mjs';

import { useQuery } from '@tanstack/react-query';

import { queryClient } from '../../query-client.mjs';
import { ClientListItem } from './ClientListItem.js';

export const ClientList = () => {
  const { data, isLoading } = useQuery<{ clients: Client[] }>(
    {
      queryKey: ['/api/v1/clients'],
    },
    queryClient,
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data?.clients) {
    return <div>Error fetching clients</div>;
  }

  if (!data?.clients.length) {
    return <div>No clients found</div>;
  }

  return (
    <div>
      {data.clients.map((client) => {
        return <ClientListItem key={client._id} client={client} />;
      })}
    </div>
  );
};
