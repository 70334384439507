import {
  Outlet,
  RouterProvider,
  Link,
  createRouter,
  createRoute,
  createRootRoute,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

import { ClientScreen } from './components/screens/ClientScreen.js';
import { ClientsScreen } from './components/screens/ClientsScreen.js';
import { MonthScreen } from './components/screens/MonthScreen.js';

const enableRouterDevtools = false;

const rootRoute = createRootRoute({
  component: () => (
    <>
      <div>
        <Link to="/">Home</Link> <Link to="/months">Data by Month</Link>{' '}
        <Link to="/clients">Data by Client</Link>
      </div>
      <hr />
      <Outlet />
      {enableRouterDevtools ?
        <TanStackRouterDevtools />
      : null}
    </>
  ),
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: function Index() {
    return (
      <div>
        <h3>Welcome Home!</h3>
      </div>
    );
  },
});

const clientsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/clients',
  component: ClientsScreen,
});

const clientRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/clients/$clientId',
  component: ClientScreen,
});

const monthsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/months',
  component: MonthScreen,
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  clientsRoute,
  clientRoute,
  monthsRoute,
]);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const Router = () => {
  return <RouterProvider router={router} />;
};
