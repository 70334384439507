import type { QueryFunction } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';

import { apiEndpoint, axiosInstance } from './axios-instance.mjs';

type QueryOptions = {
  queryParams?: Record<string, string>;
};

const defaultQueryFn: QueryFunction = async (context) => {
  const { queryKey } = context;
  const [path, options] = queryKey as [string, QueryOptions] | [string];

  const url = new URL(path, apiEndpoint);

  if (options?.queryParams !== undefined) {
    Object.entries(options.queryParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }

  const { data } = await axiosInstance.get(url.toString());

  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});
